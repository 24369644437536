import React, { useEffect, useState } from "react"
import axios from "axios"

const Form = ({ setShowSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    timeofday: "anytime",
    interested: "",
    message: "",
  })

  const [formErrors, setFormErrors] = useState({
    name: false,
    phone: false,
    email: false,
  })

  const [formDisabled, setFormDisabled] = useState(false)

  const ref = React.useRef()

  const handleSubmit = async event => {
    event.preventDefault()
    if (formDisabled) return
    setFormDisabled(true)

    // Validation
    if (!formData.name || !formData.email || !formData.phone) {
      setFormErrors({
        name: formData.name ? false : true,
        phone: formData.phone ? false : true,
        email: formData.email ? false : true,
      })
      setFormDisabled(false)
      return
    } else {
      setFormErrors({
        name: false,
        phone: false,
        email: false,
      })
    }

    try {
      // Make Axios POST request to your endpoint
      await axios.post(
        "https://8trt4ieu8l.execute-api.us-east-1.amazonaws.com/default/Bodhi-Contact-Form-BE",
        formData,
        {
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      // Reset form data after successful submission if needed
      setFormData({
        name: "",
        phone: "",
        email: "",
        timeofday: "anytime",
        interested: "",
        message: "",
      })

      ref.current.reset()
      setShowSuccess(true)

      // Handle success, e.g., show a success message to the user
      console.log("Form submitted successfully!")
      // Send gtag event
      window.gtag("event", "consultation_form_submitted", {
        page_name: "home",
        event_type: "consultation-form-submitted",
      })

      setFormDisabled(false)
    } catch (error) {
      // Handle error, e.g., show an error message to the user
      console.error("Error submitting form:", error)
      setFormDisabled(false)
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    // Update the form data in the state based on the input field's name
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit} ref={ref}>
      <div class="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          class="form-control"
          name="name"
          id="name"
          placeholder="Name"
          onChange={handleInputChange}
        />
        {formErrors.name && !formData.name && (
          <p class="error">Name is required</p>
        )}
      </div>

      <div class="form-group">
        <label htmlFor="phone">Phone number</label>
        <input
          type="tel"
          name="phone"
          class="form-control"
          id="phone"
          placeholder="Phone"
          onChange={handleInputChange}
        />
        {formErrors.phone && !formData.phone && (
          <p class="error">Phone is required</p>
        )}
      </div>

      <div class="form-group">
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          name="email"
          placeholder="Email"
          onChange={handleInputChange}
        />
        {formErrors.email && !formData.email && (
          <p class="error">Email is required</p>
        )}
      </div>

      <div class="form-group">
        <label htmlFor="timeofday">Best contact time</label>
        <div role="group">
          <label class="radio-inline">
            <input
              type="radio"
              name="timeofday"
              value="morning"
              checked={formData.timeofday === "morning"}
              onChange={handleInputChange}
            />
            Morning
          </label>
          <label class="radio-inline">
            <input
              type="radio"
              name="timeofday"
              value="afternoon"
              checked={formData.timeofday === "afternoon"}
              onChange={handleInputChange}
            />
            Afternoon
          </label>
          <label class="radio-inline">
            <input
              type="radio"
              name="timeofday"
              value="evening"
              checked={formData.timeofday === "evening"}
              onChange={handleInputChange}
            />
            Evening
          </label>
          <label class="radio-inline">
            <input
              type="radio"
              name="timeofday"
              value="anytime"
              checked={formData.timeofday === "anytime"}
              onChange={handleInputChange}
            />
            Anytime
          </label>
        </div>
      </div>

      <div class="form-group">
        <label htmlFor="interested">I'm interested in</label>

        <select
          name="interested"
          id="interested"
          class="form-control"
          onBlur={handleInputChange}
        >
          <option value="">Please choose an option</option>
          <option value="level-5">Level 5 Diploma</option>
          <option value="level-6">Level 6 Diploma</option>
        </select>
      </div>

      <div class="form-group">
        <label htmlFor="message">Message</label>
        <textarea
          class="form-control"
          name="message"
          placeholder="Message"
          id="message"
          rows="3"
          onBlur={handleInputChange}
        ></textarea>
      </div>

      <button
        type="submit"
        id="contact-form-submit"
        className="btn btn-default"
      >
        {formDisabled ? "Submiting..." : "Submit"}
      </button>
    </form>
  )
}

const Success = () => {
  return (
    <h3>
      Thank you for filling out our contact form we will get back to you as soon
      as possible.
    </h3>
  )
}

const Contact = () => {
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    return () => setShowSuccess(false)
  }, [setShowSuccess])

  return (
    <div className="container">
      <div className="row home__border-bottom">
        <div className="col-sm-6 info">
          <h3>Book a free course consultation</h3>

          <p>
            Fill out the contact form or email{" "}
            <a href="mailto:info@bodhi.ac.nz">info@bodhi.ac.nz</a>
            {". "}
            We can talk you through:
          </p>

          <ul className="bodhi__indented-list">
            <li>Full-time study options and tuition pathways with Bodhi.</li>
            <li>The application process and how to apply.</li>
            <li>Course duration and module details of each course.</li>
            <li>Fees, funded payment options, and eligibility.</li>
          </ul>
        </div>
        <div className="col-sm-6 info">
          {showSuccess && <Success />}
          {!showSuccess && <Form setShowSuccess={setShowSuccess} />}
        </div>
      </div>
    </div>
  )
}

export default Contact
