import React from "react"

const Icons = props => {
  const { type, color, width, height } = props

  const colors = { blue: `#224e7b` }

  const style = {
    maxWidth: "60px",
    fill: color ? colors[color] : `inherit`,
    fillRule: `evenodd`,
    clipRule: `evenodd`,
    width: width ? `${width}px` : `inherit`,
    height: height ? `${height}px` : `inherit`,
  }

  if (type === "computer-desk")
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 122.88 107.43"
        space="preserve"
        style={style}
      >
        <g>
          <path d="M99.89,96.65c2.9,0,5.25,0.5,5.25,1.12c0,0.62-2.35,1.12-5.25,1.12c-2.9,0-5.25-0.5-5.25-1.12 C94.63,97.16,96.98,96.65,99.89,96.65L99.89,96.65z M33.47,0h52.4c0.83,0,1.51,0.68,1.51,1.5v31.18c0,0.83-0.68,1.51-1.51,1.51 h-52.4c-0.83,0-1.51-0.68-1.51-1.51V1.51C31.96,0.68,32.64,0,33.47,0L33.47,0L33.47,0z M52.84,36.27H66.5 c0.03,2.23,0.9,4.24,3.21,5.86h50.52c0.73,0,1.39,0.3,1.87,0.77c0.48,0.48,0.78,1.14,0.78,1.87v7.86c0,0.73-0.3,1.39-0.78,1.87 c-0.48,0.48-1.14,0.78-1.87,0.78h-0.17v50.28c0,0.51-0.21,0.98-0.55,1.31l-0.01,0.01c-0.34,0.34-0.8,0.55-1.31,0.55H82.07 c-0.51,0-0.98-0.21-1.32-0.55c-0.06-0.06-0.12-0.13-0.17-0.2c-0.24-0.31-0.38-0.7-0.38-1.12V55.28H22.39v50.28 c0,0.52-0.21,0.98-0.55,1.32c-0.34,0.34-0.81,0.55-1.32,0.55h-8.86c-0.51,0-0.97-0.21-1.31-0.55l0,0l0,0l0,0 c-0.34-0.34-0.55-0.8-0.55-1.31V55.28H2.64c-0.73,0-1.39-0.3-1.87-0.78C0.3,54.02,0,53.36,0,52.63v-7.86c0-0.73,0.3-1.39,0.78-1.87 c0.48-0.48,1.14-0.77,1.87-0.77h46.94C51.49,40.65,52.85,38.85,52.84,36.27L52.84,36.27L52.84,36.27z M59.67,29.11 c1.07,0,1.93,0.87,1.93,1.93c0,1.07-0.87,1.93-1.93,1.93c-1.07,0-1.94-0.87-1.94-1.93C57.74,29.98,58.6,29.11,59.67,29.11 L59.67,29.11z M36.49,2.84h46.35c0.73,0,1.33,0.6,1.33,1.33v22.4c0,0.73-0.6,1.33-1.33,1.33H36.49c-0.73,0-1.33-0.6-1.33-1.33V4.17 C35.16,3.44,35.76,2.84,36.49,2.84L36.49,2.84L36.49,2.84z M83.28,69.49h33.7v-14.2h-33.7V69.49L83.28,69.49z M116.99,72.56h-33.7 v14.48h33.7V72.56L116.99,72.56z M116.99,90.13h-33.7v14.22h33.7V90.13L116.99,90.13z M119.8,45.2H3.08v6.99H119.8V45.2L119.8,45.2 z M19.31,55.28h-6.44v49.06h6.44V55.28L19.31,55.28z M99.89,61.12c2.9,0,5.25,0.47,5.25,1.06s-2.35,1.06-5.25,1.06 c-2.9,0-5.25-0.48-5.25-1.06S96.98,61.12,99.89,61.12L99.89,61.12z M99.89,78.86c2.9,0,5.25,0.5,5.25,1.12 c0,0.62-2.35,1.12-5.25,1.12c-2.9,0-5.25-0.5-5.25-1.12C94.63,79.36,96.98,78.86,99.89,78.86L99.89,78.86z" />
        </g>
      </svg>
    )

  if (type === "study")
    return (
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 122.88 66.26"
        space="preserve"
        style={style}
      >
        <g>
          <path
            className="st0"
            d="M2.73,60.82h10.51c-1-0.26-1.75-1.18-1.75-2.26V2.33c0-1.28,1.05-2.33,2.33-2.33h94.64 c1.28,0,2.33,1.05,2.33,2.33v56.22c0,1.08-0.74,2-1.75,2.26h11.12c1.5,0,2.73,1.22,2.73,2.73l0,0c0,1.5-1.22,2.73-2.73,2.73H2.73 c-1.5,0-2.73-1.22-2.73-2.73l0,0C0,62.04,1.22,60.82,2.73,60.82L2.73,60.82L2.73,60.82z M29.91,10.97h24.38v29.24 c-0.05,0.82-1.1,0.84-2.24,0.79H29.52c-1.58,0-2.87,1.29-2.87,2.87c0,1.58,1.29,2.87,2.87,2.87h23.57v-3.05h2.24v3.88 c0,0.71-0.58,1.28-1.28,1.28H29.63c-2.84,1.05-5.16-1.27-5.16-4.11V16.41C24.48,13.42,26.92,10.97,29.91,10.97L29.91,10.97z M66.73,47.29c-0.81,0-1.47-0.71-1.47-1.58s0.66-1.58,1.47-1.58h29.29c0.81,0,1.47,0.71,1.47,1.58s-0.66,1.58-1.47,1.58H66.73 L66.73,47.29z M66.52,12.78h32.27c0.69,0,1.26,0.57,1.26,1.26v4.5c0,0.68-0.57,1.26-1.26,1.26H66.52c-0.68,0-1.26-0.56-1.26-1.26 v-4.5C65.26,13.34,65.83,12.78,66.52,12.78L66.52,12.78z M66.73,29.63c-0.81,0-1.47-0.71-1.47-1.58c0-0.87,0.66-1.58,1.47-1.58 h27.28c0.81,0,1.47,0.71,1.47,1.58c0,0.87-0.66,1.58-1.47,1.58H66.73L66.73,29.63z M66.73,38.46c-0.81,0-1.47-0.71-1.47-1.58 s0.66-1.58,1.47-1.58h23.03c0.81,0,1.47,0.71,1.47,1.58s-0.66,1.58-1.47,1.58H66.73L66.73,38.46z M30.92,15.22h0.91 c0.46,0,0.84,0.31,0.84,0.68v21.37c0,0.37-0.38,0.68-0.84,0.68h-0.91c-0.46,0-0.84-0.31-0.84-0.68V15.9 C30.08,15.52,30.46,15.22,30.92,15.22L30.92,15.22z M15.47,3.65h91.65v54.24H15.47V3.65L15.47,3.65L15.47,3.65z M59.15,61.84h7.67 c0.72,0,1.31,0.59,1.31,1.31l0,0c0,0.72-0.59,1.31-1.31,1.31h-7.67c-0.72,0-1.31-0.59-1.31-1.31l0,0 C57.84,62.42,58.43,61.84,59.15,61.84L59.15,61.84L59.15,61.84z"
          />
        </g>
      </svg>
    )

  if (type === "heart")
    return (
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        space="preserve"
        style={style}
      >
        <g>
          <g>
            <path
              d="M467.76,88.1c-1.87-1.87-4.44-2.931-7.071-2.931c-2.64,0-5.21,1.061-7.081,2.931c-1.859,1.859-2.92,4.43-2.92,7.07
			c0,2.63,1.061,5.211,2.92,7.071c1.87,1.86,4.44,2.93,7.081,2.93c2.63,0,5.201-1.069,7.071-2.93c1.86-1.86,2.92-4.44,2.92-7.071
			C470.68,92.53,469.62,89.959,467.76,88.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M238.071,323.626c-1.869-1.87-4.44-2.931-7.071-2.931c-2.64,0-5.211,1.061-7.081,2.931c-1.859,1.859-2.92,4.43-2.92,7.07
			c0,2.63,1.061,5.21,2.92,7.071c1.87,1.86,4.44,2.93,7.081,2.93c2.63,0,5.201-1.069,7.071-2.93c1.86-1.86,2.92-4.44,2.92-7.071
			C240.992,328.056,239.932,325.485,238.071,323.626z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M491.983,121.313c-2.73-4.802-8.838-6.48-13.637-3.75c-4.801,2.73-6.48,8.836-3.749,13.637
			c29.706,52.238,20.738,118.438-21.81,160.985l-7.583,7.583c-1.832-4.807-4.67-9.316-8.537-13.182L333.911,183.831
			c6.72-3.306,12.929-7.717,18.38-13.168l19.708-19.709c3.905-3.905,3.905-10.238,0-14.143c-3.906-3.905-10.237-3.905-14.144,0
			l-19.708,19.709c-9.259,9.258-21.568,14.357-34.661,14.357h-39.397c-2.652,0-5.195,1.054-7.072,2.929l-30.16,30.16
			c-8.956,8.957-23.529,8.957-32.485,0c-8.957-8.956-8.957-23.529,0-32.485l68.856-68.855C305.307,60.546,371,51.292,422.975,80.122
			c4.83,2.677,10.918,0.936,13.597-3.895c2.679-4.83,0.935-10.918-3.895-13.597c-28.668-15.899-62.444-22.262-95.112-17.911
			c-30.572,4.071-58.555,16.872-81.493,37.184C227.893,56.922,192.02,43.261,154.04,43.261c-41.146,0-79.828,16.023-108.923,45.117
			S0,156.157,0,197.302c0,41.146,16.023,79.828,45.117,108.923l3.769,3.769c0.656,0.655,1.384,1.191,2.153,1.626
			c0.669,8.652,4.294,17.116,10.897,23.718c7.349,7.348,16.997,11.02,26.648,11.02c0.501,0,1.003-0.016,1.504-0.036
			c-0.02,0.506-0.037,1.012-0.037,1.521c0,10.066,3.92,19.53,11.038,26.648c7.349,7.348,16.997,11.021,26.648,11.02
			c0.501,0,1.003-0.016,1.504-0.036c-0.02,0.506-0.037,1.012-0.037,1.521c0,10.066,3.92,19.53,11.039,26.648
			c7.348,7.347,16.998,11.02,26.648,11.02c0.507,0,1.014-0.016,1.521-0.036c-0.406,10.151,3.251,20.437,10.984,28.171
			c7.119,7.118,16.583,11.038,26.648,11.038c10.067,0,19.531-3.92,26.648-11.038l13.977-13.978l18.879,18.877
			c7.118,7.119,16.582,11.039,26.648,11.039c10.066,0,19.53-3.92,26.648-11.038c7.826-7.826,11.482-18.267,10.972-28.537
			c0.628,0.031,1.258,0.049,1.887,0.049c9.651,0,19.303-3.673,26.65-11.021c7.729-7.729,11.386-18.007,10.984-28.153
			c0.506,0.02,1.012,0.037,1.521,0.037c10.066,0,19.53-3.92,26.648-11.038c7.729-7.729,11.386-18.007,10.984-28.153
			c0.506,0.02,1.012,0.037,1.521,0.037c10.066,0,19.53-3.92,26.648-11.038c3.615-3.615,6.337-7.791,8.173-12.248
			c0.973-0.474,1.888-1.105,2.697-1.913l19.394-19.394C515.824,257.436,526.126,181.354,491.983,121.313z M88.585,326.374
			c-4.724,0-9.165-1.839-12.506-5.18c-3.34-3.34-5.18-7.781-5.18-12.505c0-4.723,1.839-9.165,5.18-12.505l17.234-17.234
			c3.34-3.341,7.782-5.18,12.506-5.18c4.723,0,9.165,1.839,12.505,5.18c6.896,6.896,6.896,18.115,0,25.01l-17.234,17.235
			C97.75,324.534,93.31,326.374,88.585,326.374z M115.235,360.349c-3.341-3.34-5.18-7.782-5.18-12.505
			c0-4.724,1.839-9.165,5.18-12.505l17.235-17.235c0,0,0,0,0-0.001c3.34-3.34,7.781-5.18,12.505-5.18
			c4.723,0,9.165,1.839,12.505,5.18c6.896,6.896,6.896,18.115,0,25.01l-17.234,17.235
			C133.349,367.243,122.127,367.243,115.235,360.349z M154.388,399.502c-3.341-3.34-5.18-7.782-5.18-12.505
			c0-4.724,1.839-9.165,5.18-12.505l17.235-17.235c0,0,0,0,0-0.001c3.34-3.34,7.781-5.18,12.505-5.18
			c4.724,0,9.165,1.839,12.505,5.18c6.896,6.897,6.896,18.116,0,25.011l-17.235,17.235
			C172.503,406.398,161.284,406.398,154.388,399.502z M235.787,421.422l-17.235,17.236c-3.34,3.34-7.781,5.18-12.505,5.18
			c-4.723,0-9.165-1.839-12.505-5.18c-6.896-6.897-6.896-18.116,0-25.011l17.235-17.235c0,0,0,0,0-0.001
			c3.34-3.34,7.781-5.18,12.505-5.18c4.723,0,9.165,1.839,12.505,5.18c3.341,3.34,5.18,7.782,5.18,12.505
			C240.968,413.642,239.128,418.082,235.787,421.422z M422.523,325.739c-3.34,3.341-7.782,5.18-12.505,5.18
			c-4.724,0-9.165-1.839-12.505-5.18l-41.627-41.627c-0.002-0.002-0.003-0.003-0.005-0.005l-39.15-39.15
			c-3.906-3.905-10.237-3.905-14.144,0c-3.905,3.905-3.905,10.238,0,14.143l80.782,80.78c6.896,6.897,6.896,18.116,0,25.011
			c-3.34,3.341-7.782,5.18-12.505,5.18c-4.724,0-9.165-1.839-12.505-5.18l-80.782-80.781c-3.906-3.904-10.237-3.904-14.144,0
			c-3.905,3.905-3.905,10.238,0,14.144l39.146,39.145c0.003,0.003,0.006,0.007,0.009,0.01l41.628,41.626
			c6.896,6.897,6.896,18.117,0.001,25.012c-6.897,6.896-18.118,6.896-25.011,0l-41.628-41.628c-0.77-0.769-1.635-1.381-2.556-1.847
			l-11.873-11.873c-3.906-3.904-10.237-3.904-14.144,0c-3.905,3.905-3.905,10.238,0,14.144l55.702,55.7
			c6.896,6.897,6.896,18.116,0,25.011c-3.34,3.341-7.782,5.18-12.505,5.18c-4.724,0-9.165-1.839-12.505-5.18l-21.196-21.195
			c1.615-4.237,2.469-8.774,2.469-13.444c0-10.066-3.92-19.53-11.038-26.648c-7.119-7.118-16.583-11.038-26.648-11.038
			c-0.509,0-1.015,0.017-1.521,0.037c0.401-10.146-3.255-20.424-10.984-28.153c-7.119-7.118-16.583-11.038-26.648-11.038
			c-0.509,0-1.015,0.017-1.521,0.037c0.402-10.146-3.255-20.424-10.984-28.153c-7.119-7.118-16.583-11.038-26.648-11.038
			c-0.509,0-1.015,0.017-1.521,0.037c0.402-10.146-3.255-20.424-10.984-28.153c-14.694-14.694-38.605-14.693-53.299,0
			l-17.234,17.234c-2.174,2.174-4.017,4.553-5.549,7.071c-23.491-24.951-36.385-57.388-36.385-91.81
			c0-35.803,13.942-69.464,39.26-94.78c25.316-25.316,58.978-39.259,94.78-39.259c32.536,0,63.302,11.516,87.644,32.618
			l-61.457,61.457c-8.116,8.117-12.586,18.908-12.586,30.386c0,11.479,4.47,22.27,12.586,30.387
			c8.378,8.377,19.383,12.566,30.387,12.566s22.009-4.188,30.387-12.566l27.232-27.232h35.253c2.911,0,5.79-0.199,8.634-0.552
			l110.403,110.401C429.418,307.625,429.418,318.845,422.523,325.739z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )

  return null
}

export default Icons
